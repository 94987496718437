<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.UserName)"
          :variant="`light-${resolveUserRoleVariant(userData.RoleID)}`"
          size="90px"
          rounded
        />
      </template>
      <h2 class="mt-2">
        {{ userData.UserName }}
      </h2>
    </b-media>

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="ResetFormData"
      >
        <input
          v-model="userData.ID"
          type="hidden"
        >
        <b-row>
          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="User Login"
              label-for="UserLogin"
            >
              <b-form-input
                id="UserLogin"
                v-model="userData.UserLogin"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <!-- Password -->
            <b-form-group
              label="Password"
              label-for="Password"
            >
              <validation-provider
                #default="validationContext"
                name="Password"
                rules="password"
              >
                <b-form-input
                  id="Password"
                  v-model="userData.Password"
                  type="password"
                  placeholder="Blank password will not update"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <!-- Confirm Password -->
            <b-form-group
              label="Confirm Password"
              label-for="ConfirmPassword"
            >
              <validation-provider
                #default="validationContext"
                name="Confirm Password"
                rules="confirmed:Password"
              >
                <b-form-input
                  id="ConfirmPassword"
                  v-model="userData.PasswordConfirm"
                  type="password"
                  placeholder="Blank password will not update"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="User Name"
              label-for="UserName"
            >
              <validation-provider
                #default="validationContext"
                name="User Name"
                rules="required"
              >

                <b-form-input
                  id="UserName"
                  v-model="userData.UserName"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="userData.Email"
                  type="email"
                  placeholder="Email"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Status"
              label-for="user-status"
            >
              <v-select
                v-model="userData.Status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-status"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Role -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="User Role"
              label-for="user-role"
            >
              <v-select
                v-model="userData.RoleID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Reset
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm,
  // BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required,
  email,
  alphaSpaces,
  password,
  confirmed,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import router from '@/router'
import formValidation from '@core/comp-functions/forms/form-validation'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // BTable,
    // BCard,
    // BCardHeader,
    // BCardTitle,
    // BFormCheckbox,
    vSelect,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    reloadData: {
      type: Function,
      required: true,
    },
  },
  // props: ['userData', ''],
  data: () => ({
    required,
    email,
    alphaSpaces,
    password,
    confirmed,
  }),
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const toast = useToast()

    const roleOptions = [
      { label: 'Admin', value: 1 },
      { label: 'Support', value: 2 },
      { label: 'Shop Manager', value: 3 },
      { label: 'CRC', value: 4 },
      { label: 'CS', value: 5 },
      { label: 'Supply Chain', value: 6 },
    ]

    const statusOptions = [
      // { label: 'Pending', value: 'pending' },
      { label: 'Active', value: true },
      { label: 'Inactive', value: false },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    const onSubmit = () => {
      store.dispatch('app-user/updateUser', props.userData)
        .then(() => {
          router.push({ name: 'users-list' })
        }).catch(error => {
          let ErrorMsg = 'Error to update backend user'
          if (error.data.Success === false) {
            ErrorMsg = error.data.Msg
          }

          toast({
            component: ToastificationContent,
            props: {
              title: ErrorMsg,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    // const blankUserData = props.userData
    // const currentUserData = props.userData

    // console.log(blankUserData)

    // const userData = JSON.parse('{"ID":1,"UserName":"Nespresso Admin","UserLogin":"epass","Email":"epass@Nespresso.com","RoleID":6,"Status":1}')
    const resetuserData = () => {
      window.location.reload()
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      onSubmit,
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    ResetFormData() {
      this.reloadData()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
